import { useState } from "react";
import { getCookieToken } from "../util/common.util";
import { postRefreshTokenApi } from "./repository";
import {
  COMMON_ERROR_MESSAGE,
  isAccountInvalid,
  isLoginInvalid,
  isUpdatedService,
  isUserInvalid,
  isUserTimeout,
  useModalContext,
} from "../contexts/ModalContext";
import { AUTH_ERROR, MESSAGE } from "../static";
import { isError, useBannerContext } from "../contexts/BannerContext";
import { StringLiteralLike } from "typescript";

type customErrorHandling<E> = (e: Error) => { isSuccess: false; result?: E };
type apiResponse<T, E> = { isSuccess: true; result: T } | { isSuccess: false; result?: E };

export type WithTokenApi<T, B extends object, E> = {
  api: (props: B) => Promise<T>;
  beforeApiFunc?: (props: B) => void;
  customErrorHandling?: customErrorHandling<E>;
  //   再描画防止のため、状態変更を行わない場合はnoChangeStateをtrueにする
  noChangeState?: boolean;
};
export type WithTokenApiUseMessage<T, B extends object> = {
  api: (props: B) => Promise<T>;
  beforeApiFunc?: (props: B) => void;
  customErrorHandling: typeof errorMessageHandling;
  //   再描画防止のため、状態変更を行わない場合はnoChangeStateをtrueにする
  noChangeState?: boolean;
};
export type WithTokenApiNoArgs<T, E> = {
  api: () => Promise<T>;
  beforeApiFunc?: () => void;
  customErrorHandling?: customErrorHandling<E>;
  //   再描画防止のため、状態変更を行わない場合はnoChangeStateをtrueにする
  noChangeState?: boolean;
};

type errorMessageResponseType = { isSuccess: false; result: { errorMessage: string } };
type apiResponseUseErrorMsg<T> = { isSuccess: true; result: T } | errorMessageResponseType;
type errorMessageCallApiType<T, B> = (props: B) => Promise<apiResponseUseErrorMsg<T>>;
type errorMessageCallNoArgsApiType<T> = () => Promise<apiResponseUseErrorMsg<T>>;

export const setDefaultErrorMessage = <T, B>(callApi: errorMessageCallApiType<T, B>): errorMessageCallApiType<T, B> => {
  return async (props: B) => {
    const response = await callApi(props);
    if (response.isSuccess) {
      return response;
    }
    if (response.result == undefined) {
      response.result = { errorMessage: "" };
    }
    return response;
  };
};

export const setDefaultErrorMessageNoArgs = <T>(
  callApi: errorMessageCallNoArgsApiType<T>
): errorMessageCallNoArgsApiType<T> => {
  return async () => {
    const response = await callApi();
    if (response.isSuccess) {
      return response;
    }
    if (response.result == undefined) {
      response.result = { errorMessage: "" };
    }
    return response;
  };
};

export const errorMessageHandling = (e: Error): errorMessageResponseType => {
  return {
    isSuccess: false,
    result: { errorMessage: e.message },
  };
};

export const useErrorBannerHandling = (): ((e: Error) => { isSuccess: false }) => {
  const { dispatch: bannerDispatch } = useBannerContext();

  return (e: Error) => {
    bannerDispatch(isError(MESSAGE.API_GENERAL_ERROR_TITLE, e.message));
    return {
      isSuccess: false,
    };
  };
};

const useIsCommonError = () => {
  const { dispatch: modalDispatch } = useModalContext();

  const isCommonError = (e: never): { isCommon: boolean; commonErrorMessage?: string } => {
    if (!("message" in e)) {
      // 例外がmessageプロパティを持っていない場合は想定外エラーとみなす
      return { isCommon: false };
    }
    const message = (e as Error).message;
    switch (message) {
      case AUTH_ERROR.ACCOUNT_INVALID:
      case AUTH_ERROR.ACCOUNT_NO_PERMISSION:
      case AUTH_ERROR.ACCOUNT_OVER_END_DATE:
        modalDispatch(isAccountInvalid());
        return { isCommon: true, commonErrorMessage: COMMON_ERROR_MESSAGE.ACCOUNT_INVALID };
      case AUTH_ERROR.USER_INVALD:
        modalDispatch(isUserInvalid());
        return { isCommon: true, commonErrorMessage: COMMON_ERROR_MESSAGE.USER_INVALID };
      case AUTH_ERROR.FRONT_INVALID:
        modalDispatch(isUpdatedService());
        return { isCommon: true, commonErrorMessage: COMMON_ERROR_MESSAGE.UPDATED_SERVICE };
      case AUTH_ERROR.LOGIN_INVALID:
        modalDispatch(isLoginInvalid());
        return { isCommon: true, commonErrorMessage: COMMON_ERROR_MESSAGE.LOGIN_INVALID };
      case AUTH_ERROR.USER_TIMEOUT:
        modalDispatch(isUserTimeout());
        return { isCommon: true, commonErrorMessage: COMMON_ERROR_MESSAGE.USER_TIMEOUT };
      default:
        // いずれにも該当しない場合は個別例外とみなす(apiごとのバリデーションなど)
        return { isCommon: false };
    }
  };

  const isTokenValid = async (): Promise<boolean> => {
    if (getCookieToken()) {
      return true;
    }
    try {
      await postRefreshTokenApi();
      return true;
    } catch (e) {
      // トークン再取得が失敗したときはapi側で期限切れしたととみなす
      modalDispatch(isUserTimeout());
      return false;
    }
  };

  return {
    isCommonError,
    isTokenValid,
  };
};

// トークン利用のAPI呼び出しのラッパー関数
// 共通のエラーハンドリング等を行う
const useCallWithWrapper = <T, E>(props: {
  api: (...args: never[]) => Promise<T>;
  customErrorHandling?: customErrorHandling<E> | typeof errorMessageHandling;
  //   再描画防止のため、状態変更を行わない場合はnoChangeStateをtrueにする
  noChangeState?: boolean;
}): E extends typeof errorMessageHandling
  ? { isExecuting: boolean; callApiWithWrapper: (apiExecute: () => Promise<T>) => Promise<apiResponseUseErrorMsg<T>> }
  : { isExecuting: boolean; callApiWithWrapper: (apiExecute: () => Promise<T>) => Promise<apiResponse<T, E>> } => {
  const [isExecuting, setIsExecuting] = useState(false);

  const { isCommonError, isTokenValid } = useIsCommonError();

  const callApiWithWrapper = async (apiExecute: () => Promise<T>) => {
    !props.noChangeState && setIsExecuting(true);
    try {
      if (!(await isTokenValid())) {
        return { isSuccess: false } as const;
      }

      const result = await apiExecute();
      return { isSuccess: true, result } as const;
    } catch (e: unknown) {
      const { isCommon, commonErrorMessage } = isCommonError(e as never);
      if (isCommon) {
        // 共通エラーに該当した場合、共通のダイアログ表示で操作抑制するのでここで終了
        return { isSuccess: false, errorMessage: commonErrorMessage } as const;
      }
      const error =
        e instanceof Error
          ? e
          : new Error("エラーが発生しました。大変申し訳ございませんが、しばらくたってからもう一度お試しください。", {
              cause: e,
            });
      if (props.customErrorHandling) {
        return props.customErrorHandling(error);
      }
      //   呼び出し側定義の例外ハンドリングがない場合は固定でisSuccess:falseを返す
      return { isSuccess: false } as const;
    } finally {
      !props.noChangeState && setIsExecuting(false);
    }
  };
  return <
    E extends typeof errorMessageHandling
      ? {
          isExecuting: boolean;
          callApiWithWrapper: (apiExecute: () => Promise<T>) => Promise<apiResponseUseErrorMsg<T>>;
        }
      : { isExecuting: boolean; callApiWithWrapper: (apiExecute: () => Promise<T>) => Promise<apiResponse<T, E>> }
  >{ callApiWithWrapper, isExecuting };
};

type useTokenApiNoArgsResponseType<T, E> = E extends { errorMessage: StringLiteralLike }
  ? { isExecuting: boolean; callApi: () => Promise<apiResponseUseErrorMsg<T>> }
  : { isExecuting: boolean; callApi: () => Promise<apiResponse<T, E>> };

// 引数なしのトークンを使ったAPIの関数作成ロジック
// 共通でトークン無効、ユーザー情報変更などのエラー判定なども担う
export const useTokenApiNoArgs = <T, E>({
  api,
  beforeApiFunc,
  customErrorHandling,
  noChangeState,
}: WithTokenApiNoArgs<T, E>): useTokenApiNoArgsResponseType<T, E> => {
  const { callApiWithWrapper, isExecuting } = useCallWithWrapper({ customErrorHandling, api, noChangeState });

  const callApi = async () => {
    return await callApiWithWrapper(async () => {
      beforeApiFunc && beforeApiFunc();
      return await api();
    });
  };

  if (customErrorHandling === errorMessageHandling) {
    return <useTokenApiNoArgsResponseType<T, E>>{
      callApi: setDefaultErrorMessage(callApi as errorMessageCallNoArgsApiType<T>),
      isExecuting,
    };
  }

  return <useTokenApiNoArgsResponseType<T, E>>{
    callApi,
    isExecuting,
  };
};

type useTokenApiResponseType<T, B, E> = E extends { errorMessage: string }
  ? { isExecuting: boolean; callApi: (props: B) => Promise<apiResponseUseErrorMsg<T>> }
  : { isExecuting: boolean; callApi: (props: B) => Promise<apiResponse<T, E>> };

// 引数ありのトークンを使ったAPIの関数作成ロジック
// 共通でトークン無効、ユーザー情報変更などのエラー判定なども担う
export const useTokenApi = <T, B extends object, E>({
  api,
  beforeApiFunc,
  customErrorHandling,
  noChangeState,
}: WithTokenApi<T, B, E> | WithTokenApiUseMessage<T, B>): useTokenApiResponseType<T, B, E> => {
  const { callApiWithWrapper, isExecuting } = useCallWithWrapper({ customErrorHandling, api, noChangeState });

  const callApi = async (props: B) => {
    return await callApiWithWrapper(async () => {
      beforeApiFunc && beforeApiFunc(props);
      return await api(props);
    });
  };

  if (customErrorHandling === errorMessageHandling) {
    return <useTokenApiResponseType<T, B, E>>{
      callApi: setDefaultErrorMessage(callApi as errorMessageCallApiType<T, B>),
      isExecuting,
    };
  }

  return <useTokenApiResponseType<T, B, E>>{
    callApi,
    isExecuting,
  };
};
