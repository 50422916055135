import auth0 from "auth0-js";
import { useState } from "react";
import {
  AUTH0_AUDIENCE,
  AUTH0_CLIENT_ID,
  AUTH0_DATABASE_REALM,
  AUTH0_DOMAIN,
  AUTH0_RESPONSE_TYPE,
  ROUTE_PATH,
} from "../static";
import {
  decryptedString,
  deleteLoginSession,
  encryptedString,
  getTokenInfo,
  getLoginSession,
  setLoginSession,
  setOtpReciver,
} from "./common.util";
import { flowType, loginType, otpType } from "../types";
import { useNavigate } from "react-router-dom";
import { registerFrontAuthLogApi, registerLoginFailedLogApi, registerSendOtpLogApi } from "../api/api.util";

const webAuth = new auth0.WebAuth({
  domain: AUTH0_DOMAIN,
  clientID: AUTH0_CLIENT_ID,
  audience: AUTH0_AUDIENCE,
  scope: "openid profile email offline_access ",
});

export const parseHash = async (
  callFrom: string,
  hash: string = window.location.hash
): Promise<auth0.Auth0DecodedHash | null> => {
  return new Promise((resolve, reject) => {
    const loginSession = getLoginSession();
    const tokens = getTokenInfo();
    registerFrontAuthLogApi({
      userId: !loginSession
        ? undefined
        : loginSession.type == "PASSWORD"
        ? loginSession.userId
        : loginSession.phoneNumberOrEmail,
      accountId: !loginSession ? undefined : loginSession.type == "PASSWORD" ? loginSession.accountId : undefined,
      // ブラウザバックなどで問題ないがアクセスする場合もあるのでワーニングとする
      message: `<<parseHash start>> callFrom:${callFrom} flowType:${loginSession?.flowType || "NOT_SET"}`,
      options: {
        tokens,
        loginSession,
      },
    });

    webAuth.parseHash({ hash: hash }, function (err, authResult) {
      if (err) {
        registerFrontAuthLogApi({
          userId: !loginSession
            ? undefined
            : loginSession.type == "PASSWORD"
            ? loginSession.userId
            : loginSession.phoneNumberOrEmail,
          accountId: !loginSession ? undefined : loginSession.type == "PASSWORD" ? loginSession.accountId : undefined,
          // ブラウザバックなどで問題ないがアクセスする場合もあるのでワーニングとする
          message: `<<parseHash end warning>> callFrom:${callFrom} flowType:${loginSession?.flowType || "NOT_SET"}`,
          options: {
            loginSession,
            error: err,
          },
        });

        reject(new Error("Hash parse error hash:" + window.location.hash + " deteil:" + JSON.stringify(err)));
      }
      registerFrontAuthLogApi({
        userId: !loginSession
          ? undefined
          : loginSession.type == "PASSWORD"
          ? loginSession.userId
          : loginSession.phoneNumberOrEmail,
        accountId: !loginSession ? undefined : loginSession.type == "PASSWORD" ? loginSession.accountId : undefined,
        // ブラウザバックなどで問題ないがアクセスする場合もあるのでワーニングとする
        message: `<<parseHash end>> callFrom:${callFrom} flowType:${loginSession?.flowType || "NOT_SET"}`,
        options: {
          loginSession,
          result: authResult,
        },
      });

      resolve(authResult);
    });
  });
};

export const checkSession = async (callFrom: string): Promise<auth0.Auth0DecodedHash | null> => {
  registerFrontAuthLogApi({
    message: `<<checkSession start>> callFrom:${callFrom}`,
    options: {
      tokens: getTokenInfo(),
      responseType: AUTH0_RESPONSE_TYPE,
      redirectUri: window.location.origin + ROUTE_PATH.LOGIN_CALLBACK,
    },
  });

  return new Promise((resolve, reject) => {
    webAuth.checkSession(
      { responseType: AUTH0_RESPONSE_TYPE, redirectUri: window.location.origin + ROUTE_PATH.LOGIN_CALLBACK },
      function (err, authResult) {
        if (err) {
          registerFrontAuthLogApi({
            // ブラウザバックなどで問題ないがアクセスする場合もあるのでワーニングとする
            message: `<<checkSession end warning>> callFrom:${callFrom}`,
            options: {
              tokens: getTokenInfo(),
              error: err,
              result: authResult,
            },
          });
          reject(new Error("Session check error deteil:" + JSON.stringify(err)));
        }
        registerFrontAuthLogApi({
          message: `<<checkSession end>> callFrom:${callFrom}`,
          options: {
            tokens: getTokenInfo(),
            result: authResult,
          },
        });

        resolve(authResult);
      }
    );
  });
};

export const logout = async (callFromInfo: {
  userId: string;
  accountId: string;
  callFrom: string;
  loginType?: loginType;
  currentPath?: string;
}): Promise<void> => {
  const loginUrlParams = callFromInfo.loginType
    ? callFromInfo.loginType == "PASSWORD"
      ? "?fv=password"
      : `?fv=otp&ot=${callFromInfo.loginType}`
    : "";
  const redirectPath: string = callFromInfo.currentPath ?? ROUTE_PATH.LOGOUT_CALLBACK + loginUrlParams;
  registerFrontAuthLogApi({
    userId: callFromInfo.userId,
    accountId: callFromInfo.accountId,
    message: `<<logout start>> callFrom:${callFromInfo.callFrom}`,
    options: {
      returnTo: window.location.origin + redirectPath,
    },
  });
  console.log("return to:" + window.location.origin + redirectPath);
  webAuth.logout({
    returnTo: window.location.origin + redirectPath,
  });

  registerFrontAuthLogApi({
    userId: callFromInfo.userId,
    accountId: callFromInfo.accountId,
    message: `<<logout end>> callFrom:${callFromInfo.callFrom}`,
    options: {
      returnTo: window.location.origin + redirectPath,
    },
  });
};

export const saveSettingLocal = (settings: {
  accountId: string;
  userId: string;
  phoneNumberOrEmail: string;
  otpType: otpType;
}) => {
  const { accountId, userId, phoneNumberOrEmail, otpType } = settings;
  localStorage.setItem(
    "loginInfo",
    JSON.stringify({
      accountId: encryptedString(accountId),
      userId: encryptedString(userId),
      phoneNumberOrEmail: encryptedString(phoneNumberOrEmail),
      otpType: encryptedString(otpType),
    })
  );
};

export const loadLoginSettingLocal = (): {
  accountId: string;
  userId: string;
  phoneNumberOrEmail: string;
  otpType: otpType;
} => {
  const loginInfoString = localStorage.getItem("loginInfo");
  if (loginInfoString !== null) {
    const values = JSON.parse(loginInfoString);
    return {
      accountId: decryptedString(values.accountId),
      userId: decryptedString(values.userId),
      otpType: values.otpType ? (decryptedString(values.otpType) as otpType) : "SMS",
      phoneNumberOrEmail: values.phoneNumberOrEmail ? decryptedString(values.phoneNumberOrEmail) : "",
    };
  }
  return { accountId: "", userId: "", otpType: "SMS", phoneNumberOrEmail: "" };
};

export const useLoginAuth0 = () => {
  const [isLoading, setIsLoading] = useState(false);

  const callLoginAuth0 = async (
    accountId: string,
    userId: string,
    password: string,
    saveBrowser: boolean,
    currentPath: string,
    flowType: flowType
  ) => {
    if (!accountId || !userId || !password) {
      return {
        // 画面部品のバリデーションで必須チェックが表示されるので、loginErrorは出さない
        isSuccess: true,
        message: "",
      };
    }
    try {
      setIsLoading(true);

      setLoginSession({
        type: "PASSWORD",
        accountId,
        userId,
        password,
        isSaveLoginInfo: saveBrowser,
        currentPath,
        flowType,
      });

      const loginPromise = new Promise<{ status: number; message?: string }>((resolve, reject) => {
        registerFrontAuthLogApi({
          userId,
          accountId,
          message: `<<loginAuth0 start>> flowType:${flowType}`,
          options: {
            saveBrowser,
            realm: AUTH0_DATABASE_REALM,
            username: `${accountId}@@${userId}`,
            password: password,
            redirectUri: window.location.origin + ROUTE_PATH.LOGIN_CALLBACK,
            responseType: AUTH0_RESPONSE_TYPE,
          },
        });
        webAuth.login(
          {
            realm: AUTH0_DATABASE_REALM,
            username: `${accountId}@@${userId}`,
            password: password,
            redirectUri: window.location.origin + ROUTE_PATH.LOGIN_CALLBACK,
            // redirectUri: window.location.origin + ROUTE_PATH.AUTH0_PASSWORD_LOGIN,
            responseType: AUTH0_RESPONSE_TYPE,
          },
          (err: unknown) => {
            if (err) {
              deleteLoginSession();
              registerFrontAuthLogApi({
                userId,
                accountId,
                message: `<<loginAuth0 end warning>> flowType:${flowType}`,
                options: {
                  error: err,
                  saveBrowser,
                  realm: AUTH0_DATABASE_REALM,
                  username: `${accountId}@@${userId}`,
                  password: password,
                  redirectUri: window.location.origin + ROUTE_PATH.LOGIN_CALLBACK,
                  responseType: AUTH0_RESPONSE_TYPE,
                },
              });
              reject(err);
            }
            // Auth0側で失敗時しかコールバックは呼ばれない仕様なのでこちらは基本呼ばれない
            // 念のため空振った時用にエラーレスポンスを返しておく
            resolve({ status: 500 });
          }
        );
      });
      const result = await loginPromise;
      if (result.status !== 200) {
        registerLoginFailedLogApi({
          loginType: "PASSWORD",
          flowType,
          options: {
            userId,
            accountId,
            password,
          },
        });
        return {
          isSuccess: false,
          message: result.message,
        };
      }
      return {
        isSuccess: true,
      };
    } catch (e) {
      registerFrontAuthLogApi({
        userId,
        accountId,
        message: `<<loginAuth0 catch warning>> flowType:${flowType}`,
        options: {
          error: e,
          saveBrowser,
          realm: AUTH0_DATABASE_REALM,
          username: `${accountId}@@${userId}`,
          password: password,
          redirectUri: window.location.origin + ROUTE_PATH.LOGIN_CALLBACK,
          responseType: AUTH0_RESPONSE_TYPE,
        },
      });
      registerLoginFailedLogApi({
        loginType: "PASSWORD",
        flowType,
        options: {
          userId,
          accountId,
          password,
        },
      });
      return {
        isSuccess: false,
      };
    } finally {
      setIsLoading(false);
    }
  };

  return {
    isLoading,
    callLoginAuth0,
  };
};

export const useEmailOtpLogin = () => {
  const [isSending, setIsSending] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const navigate = useNavigate();

  const callEmailOtpLoginStart = async (email: string, saveLoginInfo: boolean, flowType: flowType) => {
    try {
      setIsSending(true);
      const passwordlessStartProcess = new Promise<{ status: number; message?: string }>((resolve, reject) => {
        registerFrontAuthLogApi({
          message: `<<callEmailOtpLoginStart start>> email:${email} flowType:${flowType}`,
          options: {
            saveLoginInfo,
            connection: "email",
            send: "code",
            email: email,
            authParams: {
              responseType: AUTH0_RESPONSE_TYPE,
            },
          },
        });
        registerSendOtpLogApi({
          type: "EMAIL",
          flowType,
          email,
        });
        webAuth.passwordlessStart(
          {
            connection: "email",
            send: "code",
            email: email,
            authParams: {
              responseType: AUTH0_RESPONSE_TYPE,
            },
          },
          function (err, res) {
            if (err) {
              callEmailOtpLoginStart;
              registerFrontAuthLogApi({
                message: `<<callEmailOtpLoginStart end warning>> email:${email} flowType:${flowType}`,
                options: {
                  error: err,
                  saveLoginInfo,
                  connection: "email",
                  send: "code",
                  email: email,
                  authParams: {
                    responseType: AUTH0_RESPONSE_TYPE,
                  },
                },
              });
              reject(err);
              return;
            }
            registerFrontAuthLogApi({
              message: `<<callEmailOtpLoginStart end>> email:${email} flowType:${flowType}`,
              options: {
                result: res,
                connection: "email",
                send: "code",
                email: email,
                authParams: {
                  responseType: AUTH0_RESPONSE_TYPE,
                },
              },
            });
            resolve({ status: 200, message: JSON.stringify(res) });
          }
        );
      });
      const result = await passwordlessStartProcess;
      if (result.status !== 200) {
        // 存在しないメールアドレスの場合でもOTP画面への遷移は実行する
        setOtpReciver(email, "EMAIL", saveLoginInfo, flowType);
        navigate(ROUTE_PATH.LOGIN_OTP);
        return {
          isSuccess: false,
          message: result.message,
        };
      }
      setOtpReciver(email, "EMAIL", saveLoginInfo, flowType);
      navigate(ROUTE_PATH.LOGIN_OTP);
      return {
        isSuccess: true,
      };
    } catch (e) {
      registerFrontAuthLogApi({
        message: `<<callEmailOtpLoginStart catch warning>> email:${email} flowType:${flowType}`,
        options: {
          error: e,
          connection: "email",
          send: "code",
          email: email,
          authParams: {
            responseType: AUTH0_RESPONSE_TYPE,
          },
        },
      });
      // 存在しないメールアドレスの場合でもOTP画面への遷移は実行する
      setOtpReciver(email, "EMAIL", saveLoginInfo, flowType);
      navigate(ROUTE_PATH.LOGIN_OTP);

      return {
        isSuccess: false,
      };
    } finally {
      setIsSending(false);
    }
  };

  const callEmailOptLogin = async (
    email: string,
    otp: string,
    saveBrowser: boolean,
    currentPath: string,
    flowType: flowType
  ) => {
    try {
      setIsLoading(true);

      setLoginSession({
        type: "EMAIL",
        phoneNumberOrEmail: email,
        otp,
        isSaveLoginInfo: saveBrowser,
        currentPath,
        flowType,
      });
      const passwordlessLogin = new Promise<{ status: number; message: string }>((resolve, reject) => {
        registerFrontAuthLogApi({
          message: `<<callEmailOptLogin start>> email:${email} flowType:${flowType}`,
          options: {
            otp,
            saveBrowser,
            currentPath,
            connection: "email",
            send: "code",
            email: email,
            authParams: {
              responseType: AUTH0_RESPONSE_TYPE,
            },
          },
        });
        webAuth.passwordlessLogin(
          {
            connection: "email",
            email: email,
            verificationCode: otp,
            responseType: AUTH0_RESPONSE_TYPE,
            redirectUri: window.location.origin + ROUTE_PATH.LOGIN_CALLBACK,
          },
          function (err) {
            if (err) {
              deleteLoginSession();
              registerFrontAuthLogApi({
                message: `<<callEmailOptLogin end warning>> email:${email} flowType:${flowType}`,
                options: {
                  otp,
                  saveBrowser,
                  currentPath,
                  error: err,
                  connection: "email",
                  send: "code",
                  email: email,
                  authParams: {
                    responseType: AUTH0_RESPONSE_TYPE,
                  },
                },
              });
              reject(err);
            }
            registerFrontAuthLogApi({
              message: `<<callEmailOptLogin end >> email:${email} flowType:${flowType}`,
              options: {
                otp,
                saveBrowser,
                currentPath,
                connection: "email",
                send: "code",
                email: email,
                authParams: {
                  responseType: AUTH0_RESPONSE_TYPE,
                },
              },
            });
            resolve({ status: 200, message: "" });
          }
        );
      });
      const result = await passwordlessLogin;
      if (result.status !== 200) {
        registerLoginFailedLogApi({
          loginType: "OTP-EMAIL",
          flowType,
          options: {
            email,
            otpCode: otp,
          },
        });
        return {
          isSuccess: false,
          message: result.message,
        };
      }
      return {
        isSuccess: true,
      };
    } catch (e) {
      registerFrontAuthLogApi({
        message: `<<callEmailOptLogin catch warning >> email:${email} flowType:${flowType}`,
        options: {
          error: e,
          otp,
          saveBrowser,
          currentPath,
          connection: "email",
          send: "code",
          email: email,
          authParams: {
            responseType: AUTH0_RESPONSE_TYPE,
          },
        },
      });
      registerLoginFailedLogApi({
        loginType: "OTP-EMAIL",
        flowType,
        options: {
          email,
          otpCode: otp,
        },
      });
      return {
        isSuccess: false,
      };
    } finally {
      setIsLoading(false);
    }
  };

  return {
    isSending,
    isLoading,
    callEmailOtpLoginStart,
    callEmailOptLogin,
  };
};

export const useSmsOtpLogin = () => {
  const [isSending, setIsSending] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const navigate = useNavigate();

  const callSmsOtpLoginStart = async (phoneNumber: string, saveLoginInfo: boolean, flowType: flowType) => {
    try {
      setIsSending(true);
      const passwordlessStartProcess = new Promise<{ status: number; message?: string }>((resolve, reject) => {
        registerFrontAuthLogApi({
          message: `<<callSmsOtpLoginStart start >> phoneNumber:${phoneNumber} flowType:${flowType}`,
          options: {
            saveLoginInfo,
            connection: "sms",
            send: "code",
            phoneNumber: `+81${phoneNumber}`,
            authParams: {
              responseType: AUTH0_RESPONSE_TYPE,
            },
          },
        });
        registerSendOtpLogApi({
          type: "SMS",
          flowType,
          phoneNumber,
        });
        webAuth.passwordlessStart(
          {
            connection: "sms",
            send: "code",
            // SMS送信は日本のみ限定かつ、入力欄はハイフンなしの番号に限定するので、日本の+81を付与する
            phoneNumber: `+81${phoneNumber}`,
            authParams: {
              responseType: AUTH0_RESPONSE_TYPE,
            },
          },
          function (err, res) {
            if (err) {
              registerFrontAuthLogApi({
                message: `<<callSmsOtpLoginStart end warning >> phoneNumber:${phoneNumber} flowType:${flowType}`,
                options: {
                  error: err,
                  saveLoginInfo,
                  connection: "sms",
                  send: "code",
                  phoneNumber: `+81${phoneNumber}`,
                  authParams: {
                    responseType: AUTH0_RESPONSE_TYPE,
                  },
                },
              });
              reject(err);
              return;
            }
            registerFrontAuthLogApi({
              message: `<<callSmsOtpLoginStart end >> phoneNumber:${phoneNumber} flowType:${flowType}`,
              options: {
                result: res,
                saveLoginInfo,
                connection: "sms",
                send: "code",
                phoneNumber: `+81${phoneNumber}`,
                authParams: {
                  responseType: AUTH0_RESPONSE_TYPE,
                },
              },
            });
            resolve({ status: 200, message: JSON.stringify(res) });
          }
        );
      });
      const result = await passwordlessStartProcess;
      if (result.status !== 200) {
        // 存在しない電話番号の場合でもOTP画面への遷移は実行する
        setOtpReciver(phoneNumber, "SMS", saveLoginInfo, flowType);
        navigate(ROUTE_PATH.LOGIN_OTP);
        return {
          isSuccess: false,
          message: result.message,
        };
      }
      setOtpReciver(phoneNumber, "SMS", saveLoginInfo, flowType);
      navigate(ROUTE_PATH.LOGIN_OTP);
      return {
        isSuccess: true,
      };
    } catch (e) {
      registerFrontAuthLogApi({
        message: `<<callSmsOtpLoginStart catch warning >> phoneNumber:${phoneNumber} flowType:${flowType}`,
        options: {
          error: e,
          saveLoginInfo,
          connection: "sms",
          send: "code",
          phoneNumber: `+81${phoneNumber}`,
          authParams: {
            responseType: AUTH0_RESPONSE_TYPE,
          },
        },
      });
      // 存在しない電話番号の場合でもOTP画面への遷移は実行する
      setOtpReciver(phoneNumber, "SMS", saveLoginInfo, flowType);
      navigate(ROUTE_PATH.LOGIN_OTP);

      return {
        isSuccess: false,
      };
    } finally {
      setIsSending(false);
    }
  };

  const callSmsOptLogin = async (
    phoneNumber: string,
    otp: string,
    saveBrowser: boolean,
    currentPath: string,
    flowType: flowType
  ) => {
    try {
      setIsLoading(true);

      setLoginSession({
        type: "SMS",
        phoneNumberOrEmail: phoneNumber,
        otp,
        isSaveLoginInfo: saveBrowser,
        currentPath,
        flowType,
      });

      const passwordlessLogin = new Promise<{ status: number; message: string }>((resolve, reject) => {
        registerFrontAuthLogApi({
          message: `<<callSmsOptLogin start >> phoneNumber:${phoneNumber} flowType:${flowType}`,
          options: {
            otp,
            saveBrowser,
            connection: "sms",
            // SMS送信は日本のみ限定かつ、入力欄はハイフンなしの番号に限定するので、日本の+81を付与する
            phoneNumber: `+81${phoneNumber}`,
            verificationCode: otp,
            responseType: AUTH0_RESPONSE_TYPE,
            redirectUri: window.location.origin + ROUTE_PATH.LOGIN_CALLBACK,
          },
        });
        webAuth.passwordlessLogin(
          {
            connection: "sms",
            // SMS送信は日本のみ限定かつ、入力欄はハイフンなしの番号に限定するので、日本の+81を付与する
            phoneNumber: `+81${phoneNumber}`,
            verificationCode: otp,
            responseType: AUTH0_RESPONSE_TYPE,
            redirectUri: window.location.origin + ROUTE_PATH.LOGIN_CALLBACK,
          },
          function (err) {
            if (err) {
              deleteLoginSession();
              registerFrontAuthLogApi({
                message: `<<callSmsOptLogin end warning >> phoneNumber:${phoneNumber} flowType:${flowType}`,
                options: {
                  error: err,
                  otp,
                  saveBrowser,
                  connection: "sms",
                  // SMS送信は日本のみ限定かつ、入力欄はハイフンなしの番号に限定するので、日本の+81を付与する
                  phoneNumber: `+81${phoneNumber}`,
                  verificationCode: otp,
                  responseType: AUTH0_RESPONSE_TYPE,
                  redirectUri: window.location.origin + ROUTE_PATH.LOGIN_CALLBACK,
                },
              });
              reject(err);
            }
            registerFrontAuthLogApi({
              message: `<<callSmsOptLogin end >> phoneNumber:${phoneNumber} flowType:${flowType}`,
              options: {
                otp,
                saveBrowser,
                connection: "sms",
                // SMS送信は日本のみ限定かつ、入力欄はハイフンなしの番号に限定するので、日本の+81を付与する
                phoneNumber: `+81${phoneNumber}`,
                verificationCode: otp,
                responseType: AUTH0_RESPONSE_TYPE,
                redirectUri: window.location.origin + ROUTE_PATH.LOGIN_CALLBACK,
              },
            });
            resolve({ status: 200, message: "" });
          }
        );
      });
      const result = await passwordlessLogin;
      if (result.status !== 200) {
        registerLoginFailedLogApi({
          loginType: "OTP-SMS",
          flowType,
          options: {
            phoneNumber,
            otpCode: otp,
          },
        });
        return {
          isSuccess: false,
          message: result.message,
        };
      }
      return {
        isSuccess: true,
      };
    } catch (e) {
      registerFrontAuthLogApi({
        message: `<<callSmsOptLogin catch warning >> phoneNumber:${phoneNumber} flowType:${flowType}`,
        options: {
          error: e,
          otp,
          saveBrowser,
          connection: "sms",
          // SMS送信は日本のみ限定かつ、入力欄はハイフンなしの番号に限定するので、日本の+81を付与する
          phoneNumber: `+81${phoneNumber}`,
          verificationCode: otp,
          responseType: AUTH0_RESPONSE_TYPE,
          redirectUri: window.location.origin + ROUTE_PATH.LOGIN_CALLBACK,
        },
      });
      registerLoginFailedLogApi({
        loginType: "OTP-SMS",
        flowType,
        options: {
          phoneNumber,
          otpCode: otp,
        },
      });
      return {
        isSuccess: false,
      };
    } finally {
      setIsLoading(false);
    }
  };

  return {
    isSending,
    isLoading,
    callSmsOtpLoginStart,
    callSmsOptLogin,
  };
};
