import React, { useState } from "react";
import { createPortal } from "react-dom";
import { useLocation, useNavigate } from "react-router-dom";
import "./PortalModal.scss";
import { useModalContext } from "../../contexts/ModalContext";
import { Icon } from "semantic-ui-react";
import { ROUTE_PATH } from "./../../static";
import { reload } from "./../../util/common.util";
import { logout } from "../../util/auth0.util";
import { useUserContext } from "../../contexts/UserContext";
import { CustomLoader } from "../Custom";

const PortalModal = (props: { parentElementSelector: string }) => {
  const { contextData } = useModalContext();
  const { contextData: user } = useUserContext();
  const [isRefreshing, setIsRefreshing] = useState(false);

  const navigation = useNavigate();
  const location = useLocation();

  const renderModal = () => {
    const refreshAction = async () => {
      const type = contextData.actionType;
      setIsRefreshing(true);
      switch (type) {
        case "RELOAD_USER":
          // ページパスがROUTE_PATH.BASEの場合はリロード
          if (location.pathname === ROUTE_PATH.BASE) {
            reload();
          } else {
            navigation(ROUTE_PATH.BASE);
            reload();
          }
          return;
        case "USER_TIMEOUT":
          logout({
            userId: user.userId,
            accountId: user.accountId,
            callFrom: "user timeout",
            loginType: user.loginType,
          });
          return;
        case "RELOAD_ALL":
          reload();
          return;
        default:
          return;
      }
    };

    const closeModal = async () => {
      if (isRefreshing) {
        return;
      }
      refreshAction();
    };

    setTimeout(async () => {
      refreshAction();
    }, 5000);

    return isRefreshing ? (
      <CustomLoader isLoading />
    ) : (
      <div onClick={closeModal} className="ui dimmer modals visible active portal-modal ">
        <div
          onClick={(e) => e.stopPropagation()}
          className={"ui standard modal visible active " + contextData.sizeClass}
        >
          <div className="ui header">
            <Icon name="info circle" className="modal-info-icon" />
            <span>{contextData.title}</span>
          </div>
          <div className="content modal-content">{contextData.message}</div>
          <div className="actions">
            <button className="ui button primary" onClick={closeModal}>
              OK
            </button>
          </div>
        </div>
      </div>
    );
  };

  const parentElement = document.querySelector(props.parentElementSelector);
  return parentElement && createPortal(contextData.isDisplayed && renderModal(), parentElement);
};

export default PortalModal;
