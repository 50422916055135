import React, { Suspense } from "react";
import { createRoot } from "react-dom/client";
import "./index.css";
import Top from "./Top";
import reportWebVitals from "./reportWebVitals";
import "semantic-ui-less/semantic.less";
import { BrowserRouter as Router } from "react-router-dom";
import { AuthProvider } from "./contexts/AuthContext";
import { ModalProvider } from "./contexts/ModalContext";
import { BannerProvider } from "./contexts/BannerContext";
import { FloatBannerProvider } from "./contexts/FloatBannerContext";
import { BadgeProvider } from "./contexts/BadgeContext";
import { CustomListProvider } from "./contexts/CustomListContext";
import { UserProvider } from "./contexts/UserContext";
import { PdfProvider } from "./contexts/PdfContext";
import { ToastBannerProvider } from "./contexts/ToastBannerContext";
import { Dimmer, Segment } from "semantic-ui-react";
import { appendHeaderFullstarScript } from "./analytics/fullstar";

const container = document.getElementById("root");
if (container) {
  const root = createRoot(container);

  appendHeaderFullstarScript();

  root.render(
    // <React.StrictMode>
    <Router>
      <BannerProvider>
        <FloatBannerProvider>
          <ToastBannerProvider>
            <ModalProvider>
              <AuthProvider>
                <BadgeProvider>
                  <CustomListProvider>
                    <PdfProvider>
                      <UserProvider>
                        <Suspense
                          fallback={
                            <Dimmer.Dimmable as={Segment} dimmed={true}>
                              {/* ほぼすぐにTopのほうでLoaderがでるのでここではLoaderの動きがもどらないようDimmerだけ表示 */}
                              {/* ここにいれとかないと描画待ちの一瞬、まっしろの画面がまざる */}
                              <Dimmer active={true} page />
                            </Dimmer.Dimmable>
                          }
                        >
                          <Top />
                        </Suspense>
                      </UserProvider>
                    </PdfProvider>
                  </CustomListProvider>
                </BadgeProvider>
              </AuthProvider>
            </ModalProvider>
          </ToastBannerProvider>
        </FloatBannerProvider>
      </BannerProvider>
    </Router>
    // </React.StrictMode>
  );
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
