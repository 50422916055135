// 環境変数から取得する値はここを参照する
// 変数名はstatic.txと重複しては行けない
export const ENV_MESSAGE = process.env.REACT_APP_MESSAGE as string;
export const TOKEN_COOKIE_NAME = process.env.REACT_APP_COOKIE_NAME as string;
export const REFRESH_COOKIE_NAME = process.env.REACT_APP_REFRESH_COOKIE_NAME as string;
export const USER_COOKIE_NAME = process.env.REACT_APP_USER_COOKIE_NAME as string;
export const COOKIE_DOMAIN = process.env.REACT_APP_DOMAIN as string;
export const APP_DOMAIN = process.env.REACT_APP_DOMAIN as string;
export const SESSION_USER_INFO = process.env.REACT_APP_SESSION_USER_INFO as string;
export const API_URL = process.env.REACT_APP_API_URL as string;
export const DEPLOY_DATE_COOKIE_NAME = process.env.REACT_APP_DEPLOY_DATE_COOKIE_NAME as string;
// CloudFrontの署名付きCookieのドメインは環境問わず、最上位のドメイン名を指定（本番と他環境のサブドメインで重複定義が起きるのを防ぐため）
export const MEDIA_COOKIE_DOMAIN = process.env.REACT_APP_MEDIA_COOKIE_DOMAIN as string;
export const MEDIA_COOKIE_ENV = process.env.REACT_APP_MEDIA_COOKIE_ENV as string;
export const MAIL_ADDRESS_INPUT = process.env.REACT_APP_MAIL_ADDRESS_INPUT as string;
export const LOGIN_SESSION_INFO = "login_session_info" + process.env.REACT_APP_SESSION_ENV_SUFFIX;
export const OTP_SESSION_INFO = "otp_session_info" + process.env.REACT_APP_SESSION_ENV_SUFFIX;
export const IS_AUTO_MIGRATE = "is_auto_migrate" + process.env.REACT_APP_SESSION_ENV_SUFFIX;
export const AUTH0_DOMAIN = process.env.REACT_APP_AUTH0_DOMAIN as string;
export const AUTH0_CLIENT_ID = process.env.REACT_APP_AUTH0_CLIENT_ID as string;
export const AUTH0_SCOPE = process.env.REACT_APP_AUTH0_SCOPE as string;
export const AUTH0_RESPONSE_TYPE = process.env.REACT_APP_AUTH0_RESPONSE_TYPE as string;
export const AUTH0_AUDIENCE = process.env.REACT_APP_AUTH0_AUDIENCE as string;
export const AUTH0_DATABASE_REALM = process.env.REACT_APP_AUTH0_DATABASE_REALM as string;
export const FRONT_DEPLOY_DATE = process.env.REACT_APP_FRONT_DEPLOY_DATE as string;
export const FULLSTAR_ID = process.env.REACT_APP_FULLSTAR_ID as string;
