import React, { createContext, useReducer, useContext } from "react";
import { childrenProps } from "../types";
import { SemanticSIZES } from "semantic-ui-react";

type ModalState = {
  isDisplayed: boolean;
  title: string | null;
  message: string | null;
  status: "info" | "error" | "success" | null;
  actionType: "RELOAD_USER" | "USER_TIMEOUT" | "RELOAD_ALL" | null;
  sizeClass?: SemanticSIZES;
};

const initialState = {
  isDisplayed: false,
  title: "",
  message: "",
  status: null,
  actionType: null,
} as ModalState;

const ModalContext = createContext(
  {} as {
    contextData: ModalState;
    dispatch: ModalDispatch;
  }
);

export type ModalDispatch = React.Dispatch<action>;

type actionType =
  | "HIDE"
  | "IS_RELOAD_USER"
  | "IS_USER_TIMEOUT"
  | "IS_USER_INVALID"
  | "IS_ACCOUNT_INVALID"
  | "IS_ACCOUNT_OVER_END_DATE"
  | "IS_RELOAD_USER_CACHE"
  | "IS_RELOAD_SERVICE"
  | "IS_LOGIN_INVALID"
  | "SKIP_ACTION";

type action = {
  type: actionType;
  title: string | null;
  message: string | null;
};

// apiが非同期で走ることによるダイアログの重複表示を防止
// リロードにより戻すか、logoutなどの際に合わせてリセットする
let isAlreadyDisplayed = false;

const skipAction = (): action => {
  // ダイアログの多重表示防止のためのスキップアクション
  return { type: "SKIP_ACTION", title: null, message: null };
};

export const COMMON_ERROR_MESSAGE = {
  ACCOUNT_INVALID: "施設が無効化されました。トップページに戻ります。",
  ACCOUNT_OVER_END_DATE:
    "利用期間が終了したため、ログインすることができません。ログインをご希望の場合は、担当者へご連絡ください。",
  USER_INVALID: "ユーザーが無効化されました。トップページに戻ります。",
  UPDATED_SERVICE: "DM Studyのバージョンが更新されましたので5秒後に再読み込みします。",
  LOGIN_INVALID: "現在のログイン方法が無効化されました。トップページに戻ります。",
  USER_TIMEOUT: "ログイン情報の有効期限が切れました。トップページに戻ります。",
  SELF_USER_UPDATED: "ログイン中のユーザー情報が更新されました。ログアウトします。",
  SELF_USER_DELETED: "ログイン中ユーザーを削除しました。ログアウトします。",
  SELF_USER_TYPE_UPDATED: "ログイン中のユーザー権限が更新されました。トップページに戻ります。",
};

export const isReloadUser = (): action => {
  return isAlreadyDisplayed
    ? skipAction()
    : {
        type: "IS_RELOAD_USER",
        title: "ログイン情報",
        message: "ログイン情報が変更されました。トップページに戻ります。",
      };
};

export const isUserTimeout = (): action => {
  return isAlreadyDisplayed
    ? skipAction()
    : {
        type: "IS_USER_TIMEOUT",
        title: "有効期限切れ",
        message: COMMON_ERROR_MESSAGE.USER_TIMEOUT,
      };
};

export const isSelfUserUpdated = (): action => {
  return isAlreadyDisplayed
    ? skipAction()
    : {
        type: "IS_USER_TIMEOUT",
        title: "ユーザー情報更新",
        message: COMMON_ERROR_MESSAGE.SELF_USER_UPDATED,
      };
};

export const isSelfUserDeleted = (): action => {
  return isAlreadyDisplayed
    ? skipAction()
    : {
        type: "IS_USER_TIMEOUT",
        title: "ユーザー情報削除",
        message: COMMON_ERROR_MESSAGE.SELF_USER_UPDATED,
      };
};

export const isSelfUserTypeChanged = (): action => {
  return isAlreadyDisplayed
    ? skipAction()
    : {
        type: "IS_RELOAD_USER",
        title: "ユーザー情報更新",
        message: COMMON_ERROR_MESSAGE.SELF_USER_TYPE_UPDATED,
      };
};

export const isLoginInvalid = (): action => {
  return isAlreadyDisplayed
    ? skipAction()
    : {
        type: "IS_LOGIN_INVALID",
        title: "無効なログイン方法",
        message: COMMON_ERROR_MESSAGE.LOGIN_INVALID,
      };
};

export const isUserInvalid = (): action => {
  return isAlreadyDisplayed
    ? skipAction()
    : {
        type: "IS_USER_INVALID",
        title: "無効なユーザー",
        message: COMMON_ERROR_MESSAGE.USER_INVALID,
      };
};

export const isAccountInvalid = (): action => {
  return isAlreadyDisplayed
    ? skipAction()
    : {
        type: "IS_ACCOUNT_INVALID",
        title: "無効な施設",
        message: COMMON_ERROR_MESSAGE.ACCOUNT_INVALID,
      };
};

export const isAccountOverEndDate = (): action => {
  return isAlreadyDisplayed
    ? skipAction()
    : {
        type: "IS_ACCOUNT_INVALID",
        title: "利用期間終了",
        // 利用中のエラーメッセージは施設無効と同様とする
        message: COMMON_ERROR_MESSAGE.ACCOUNT_INVALID,
      };
};

export const isReloadUserCache = (): action => {
  return isAlreadyDisplayed
    ? skipAction()
    : {
        type: "IS_RELOAD_USER_CACHE",
        title: "ユーザー情報更新",
        message:
          "ユーザー・施設情報が更新されましたので5秒後に再読み込みします。" +
          "\n" +
          "自動で再読み込みがされない場合はOKボタンを押してください。",
      };
};

export const isUpdatedService = (): action => {
  return isAlreadyDisplayed
    ? skipAction()
    : {
        type: "IS_RELOAD_SERVICE",
        title: "サービス更新",
        message:
          COMMON_ERROR_MESSAGE.UPDATED_SERVICE + "\n" + "自動で再読み込みがされない場合はOKボタンを押してください。",
      };
};

export const hide = (): action => {
  return { type: "HIDE", title: null, message: null };
};

const modalReducer = (state: ModalState, action: action): ModalState => {
  if (action.type !== "HIDE" && state.isDisplayed) {
    // すでに何かメッセージを出している場合はskip
    return state;
  }
  if (action.type !== "HIDE" && isAlreadyDisplayed) {
    // すでに何かメッセージを出している場合はskip
    return state;
  }
  if (action.type !== "HIDE") {
    isAlreadyDisplayed = true;
  }
  switch (action.type) {
    // case "IS_ERROR":
    //   return { isDisplayed: true, title: action.title, message: action.message, status: "error" };
    // case "IS_SUCCESS":
    //   return { isDisplayed: true, title: action.title, message: action.message, status: "success" };
    // case "IS_INFO":
    //   return { isDisplayed: true, title: action.title, message: action.message, status: "info" };
    case "IS_RELOAD_USER":
      return {
        isDisplayed: true,
        title: action.title,
        message: action.message,
        status: "info",
        actionType: "RELOAD_USER",
      };
    case "IS_USER_TIMEOUT":
    case "IS_USER_INVALID":
    case "IS_ACCOUNT_INVALID":
    case "IS_ACCOUNT_OVER_END_DATE":
    case "IS_LOGIN_INVALID":
      return {
        isDisplayed: true,
        title: action.title,
        message: action.message,
        status: "info",
        actionType: "USER_TIMEOUT",
      };
    case "IS_RELOAD_USER_CACHE":
    case "IS_RELOAD_SERVICE":
      return {
        isDisplayed: true,
        title: action.title,
        message: action.message,
        status: "info",
        actionType: "RELOAD_ALL",
        sizeClass: "tiny",
      };
    case "HIDE":
      return { isDisplayed: false, title: null, message: null, status: null, actionType: null };
    default:
      return state;
  }
};

export const ModalProvider = (props: childrenProps) => {
  const [contextData, dispatch] = useReducer(modalReducer, initialState);
  const data = { contextData, dispatch };
  return <ModalContext.Provider value={data} {...props} />;
};

export const useModalContext = () => {
  return useContext(ModalContext);
};
