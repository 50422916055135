import React, { lazy, Suspense, useEffect, useState } from "react";
import { authLogin, authLogout, useAuthContext } from "./contexts/AuthContext";
import { checkSession, useLoginAuth0 } from "./util/auth0.util";
import {
  clearAuthCookie,
  getCookieRefreshToken,
  getLoginSession,
  isAuthRedirecting,
  isAutoMigrate,
  setDeployDateCookie,
  setIsAutoMigrate,
  startChangeCookieLisner,
  stopChangeCookieLisner,
} from "./util/common.util";
import { refrershTokenApi } from "./api/repository";
import { Dimmer, Loader, Segment } from "semantic-ui-react";
import { FRONT_DEPLOY_DATE } from "./static";
import { useCheckFrontVersionApi } from "./api/api.hooks";
import PortalModal from "./components/PortalModal";

const PrivateRoute = lazy(() => import("./pages/PrivateRoute"));
const PublicRoute = lazy(() => import("./pages/PublicRoute"));

const Top = () => {
  // 注意 Topコンポーネントに他のstateを追加しないこと
  // URL直うちやリロード、Auth0などからのredirectした初回のみ読まれる前提としているため
  const { isOldFrontVersion, callCheckFrontVersionApi } = useCheckFrontVersionApi();

  const checkFrontVersion = async () => {
    setDeployDateCookie(FRONT_DEPLOY_DATE);
    await callCheckFrontVersionApi();
  };

  useEffect(() => {
    checkFrontVersion();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Suspense fallback={<DimmerPageLoader />}>
      {isOldFrontVersion ? (
        <>
          <Dimmer active>
            <Loader>読み込んでいます...</Loader>
          </Dimmer>
          <PortalModal parentElementSelector="#root" />
        </>
      ) : (
        <AuthenticatedSwitch />
      )}
    </Suspense>
  );
};

const AuthenticatedSwitch = () => {
  const [isInitialize, setIsInitialize] = useState(true);
  const { contextData: auth, dispatch } = useAuthContext();
  const { callLoginAuth0 } = useLoginAuth0();

  const appRoute = isInitialize ? (
    <DimmerPageLoader />
  ) : auth.isLoggedIn && !isAutoMigrate() ? (
    <PrivateRoute />
  ) : (
    <PublicRoute />
  );

  const tryOldRefreshTokenMigration = async (refreshToken: string) => {
    try {
      clearAuthCookie();
      setIsAutoMigrate(true);
      const res = await refrershTokenApi(refreshToken);
      if (res.status != 200) {
        throw new Error("無効なトークン");
      }
      const { accountId, userId, password } = res.loginInfo;
      await callLoginAuth0(
        accountId,
        userId,
        password,
        false,
        window.location.pathname + window.location.search,
        "MIGRATE_AUTO_LOGIN"
      );
    } catch (e) {
      setIsAutoMigrate(false);
      dispatch(authLogout(false));
    }
  };

  useEffect(() => {
    startChangeCookieLisner();
    auth.isInitialized
      ? (async () => {
          try {
            const loginSession = getLoginSession();
            if (loginSession) {
              return;
            }
            const res = await checkSession("top effect");
            if (res == null || res.accessToken == null || res.expiresIn == null) {
              throw new Error("トークン取得失敗");
            }
            dispatch(
              authLogin({
                accessToken: res.accessToken,
                expiresIn: res.expiresIn,
              })
            );
          } catch {
            // エラーハンドリングは潰す トークン切れや初回ログイン時は必ず発生するためエラー情報は出力しない
            const refreshToken = getCookieRefreshToken();
            if (refreshToken) {
              await tryOldRefreshTokenMigration(refreshToken);
            } else {
              dispatch(authLogout(false));
            }
          } finally {
            setIsInitialize(false);
          }
        })()
      : setIsInitialize(false);

    return () => {
      stopChangeCookieLisner();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Suspense fallback={<DimmerPageLoader />}>
        {isAuthRedirecting() && <DimmerPageLoader />}
        {appRoute}
      </Suspense>
    </>
  );
};

export const DimmerPageLoader = () => {
  return (
    <Dimmer.Dimmable as={Segment} dimmed={true}>
      <Dimmer active={true} page>
        <Loader>読み込んでいます...</Loader>
      </Dimmer>
    </Dimmer.Dimmable>
  );
};

export default Top;
